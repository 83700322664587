<script>

import router from '../../index'
import {
  authMethods,
  authFackMethods,
} from "@/state/helpers";
export default {
  created() {
    this.logOut();
    router.push('/login'); 
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
  }
}
</script>
